export const FirebaseConfig = {
	"projectId": "atob-worldwide",
	"appId": "1:584106954393:web:761ee7ea7faea4be8ef208",
	"databaseURL": "https://atob-worldwide-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "atob-worldwide.appspot.com",
	"locationId": "europe-west",
	"apiKey": "AIzaSyDIUh79-0rOJQIEn9gKwvfh4gFlnFgIKkA",
	"authDomain": "atob-worldwide.firebaseapp.com",
	"messagingSenderId": "584106954393",
	"measurementId": "G-9CSFQ0FL95"
};